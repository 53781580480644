<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  created: function() {
    this.$http.interceptors.response.use(undefined, (err) => {
      return new Promise(() => {
        if (err.toString().includes("403") || err.toString().includes("401")) {
          if (!this.wasConfirm) {
            this.wasConfirm = true;
            if (
              confirm(
                `${this.$const.MESSAGE.NotAuthorization} ${this.$const.MESSAGE.WannaLogout}`
              )
            ) {
              this.$user.dispatch("logout").then(() => {
                let returnUrl = this.$router.currentRoute.fullPath;
                let query = returnUrl ? { returnUrl } : null;
                this.$router.push({ name: "Login", query });
              });
              return;
            }
          }
        } else {
          alert(err);
        }
        // if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        //   this.$user.dispatch("logout");
        // }
        throw err;
      });
    });
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
@import "vue-select/src/scss/vue-select.scss";

.v-select {
  .vs__dropdown-toggle {
    height: calc(1.5em + 0.75rem + 2px);
    padding-top: 3px;
  }
}
</style>
