import Vue from "vue";
import Router from "vue-router";
import user from "@/store/user.js";

// Containers
function TheContainer() {
  return import("@/containers/TheContainer");
}

// Views
const Dashboard = () => import("@/views/dashboard/Index.vue");

// Views - Customer
const CustomerVisit = () => import("@/views/customer/Visit.vue");
const CustomerList = () => import("@/views/customer/List.vue");

// Views - Order
const OrderCreate = () => import("@/views/order/Create.vue");
const OrderList = () => import("@/views/order/List.vue");
const Installment = () => import("@/views/installment/Index.vue");
const AgencyExpense = () => import("@/views/agencyExpense/Index.vue");

// Views - Inventory
const InventoryImport = () => import("@/views/inventoryImport/Index.vue");
const InventoryExport = () => import("@/views/inventoryExport/Index.vue");
const InventoryWarehouse = () => import("@/views/warehouse/Index.vue");

// Views - MISC
const Product = () => import("@/views/product/Index.vue");
// Views - MISC
const Misc = () => import("@/views/misc/Index.vue");
// Views - Promo
const Promo = () => import("@/views/promotion/Index.vue");
// Views - Agency
const Agency = () => import("@/views/agency/Index.vue");
// Views - Expense
const Expense = () => import("@/views/expense/Index.vue");
// Views - User
const User = () => import("@/views/user/Index.vue");

// Views - Settings
const Profile = () => import("@/views/profile/Index.vue");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

// Views - Warranty
const Warranty = () => import("@/views/warranty/Index.vue");
// Views - Report
const Report = () => import("@/views/report/Index.vue");

const ReportV2 = () => import("@/views/chart-test/Index.vue");
const Locked = () => import("@/views/pages/Locked.vue");

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: configRoutes(),
});

router.beforeEach((to, from, next) => {
  // if (to.name != "Locked") {
  //   next({ name: "Locked" });
  // } else {
  //   next();
  // }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let authUser = user.getters.authUser;
    if (authUser) {
      if (
        to.matched.every(
          (record) =>
            !record.meta.roles ||
            !record.meta.roles.length ||
            record.meta.roles.includes(authUser.role)
        )
      ) {
        next();
        return;
      } else {
        next({ name: "Page404" });
      }
    } else {
      let query = to.fullPath ? { returnUrl: to.fullPath } : null;
      next({ name: "Login", query });
    }
  } else {
    next();
  }
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "reportV2",
          name: "Report-V2",
          component: ReportV2,
          meta: {
            roles: ["super_admin", "owner"],
          },
        },
        {
          path: "report",
          Name: "Report",
          component: Report,
          meta: {
            roles: ["super_admin", "owner"],
          },
        },
        {
          path: "customer",
          redirect: "/customer/list",
          name: "Customer",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            roles: ["super_admin", "owner", "agent"],
          },
          children: [
            {
              path: "visit/:customerId?",
              name: "Visit",
              component: CustomerVisit,
            },
            {
              path: "list",
              name: "List",
              component: CustomerList,
            },
          ],
        },
        {
          path: "order",
          redirect: "/order/list",
          name: "Order",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            roles: ["super_admin", "owner", "agent"],
          },
          children: [
            {
              path: "create/:visitId?",
              name: "OrderCreate",
              component: OrderCreate,
            },
            {
              path: "list/:orderId?",
              name: "OrderList",
              component: OrderList,
            },
            {
              path: "installment",
              name: "Installment",
              component: Installment,
            },
            {
              path: "expense",
              name: "AgencyExpense",
              component: AgencyExpense,
              meta: {
                roles: ["super_admin", "owner"],
              },
            },
          ],
        },

        {
          path: "inventory",
          redirect: "/inventory/import",
          name: "Inventory",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            roles: ["super_admin", "owner", "stock_admin"],
          },
          children: [
            {
              path: "import",
              name: "InventoryImport",
              component: InventoryImport,
            },
            {
              path: "export",
              name: "InventoryExport",
              component: InventoryExport,
            },
            {
              path: "warehouse",
              name: "InventoryWarehouse",
              component: InventoryWarehouse,
            },
          ],
        },

        {
          path: "general",
          redirect: "general/misc",
          name: "General",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "product",
              Name: "Product",
              component: Product,
              meta: {
                roles: ["super_admin", "stock_admin"],
              },
            },
            {
              path: "promotion",
              Name: "Promotion",
              component: Promo,
              meta: {
                roles: ["super_admin"],
              },
            },
            {
              path: "misc",
              Name: "Misc",
              component: Misc,
              meta: {
                roles: ["super_admin"],
              },
            },
            {
              path: "agency",
              Name: "Agency",
              component: Agency,
              meta: {
                roles: ["super_admin", "owner"],
              },
            },
            {
              path: "expense",
              Name: "Expense",
              component: Expense,
              meta: {
                roles: ["super_admin"],
              },
            },
            {
              path: "user",
              Name: "User",
              component: User,
              meta: {
                roles: ["super_admin"],
              },
            },
          ],
        },
        {
          path: "settings",
          redirect: "/settings/profile",
          name: "Settings",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "profile",
              name: "Profile",
              component: Profile,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
        {
          path: "register",
          name: "Register",
          component: Register,
        },
      ],
    },
    {
      path: "/bao-hanh",
      name: "Warranty",
      component: Warranty,
    },
    {
      path: "/locked",
      name: "Locked",
      component: Locked,
    },
  ];
}

export default router;
