const Contants = {
  MESSAGE: {
    LoginSuccess: "Đăng nhập thành công!",
    RequiredUserInfo: "Vui lòng nhập thông tin người dùng!",
    UserNotExist: "Người dùng không tồn tại!",
    LogoutSuccess: "Đăng xuất thành công!",
    NotAuthorization: "Không có quyền thực hiện chức năng này!",
    WannaLogout: "Bạn có muốn đăng xuất?",
    InvalidOrderInfo: "Thông tin đơn hàng chưa đúng. Vui lòng kiểm tra lại!",
    CreateOrderSuccess: "Tạo đơn hàng thành công!",
    UpdateSuccess: "Cập nhật thành công!",
    UpdateError: "Cập nhật không thành công!",
    InvalidData: "Thông tin chưa đúng. Vui lòng kiểm tra lại",
    WrongPassword: "Nhập sai mật khẩu!",
    WrongAmount: "Số tiền chưa đúng",
    PasswordSuccess:
      "Đổi mật khẩu thành công. Hãy đăng nhập lại bằng mật khẩu mới!",
    SendOmniSuccess: "Hệ thống đã ghi nhận yêu cầu gửi tin nhắn zalo!",
    NoPhoneNumber: "Không có số điện thoại!",
    SendOmniError: "Lỗi gửi tin nhắn zalo!",
    NoInventoryStock: "Hiện không có tồn kho!",
    CaresoftTicketSuccess: "Hệ thống đã tạo phiếu ghi CSKH sau bán hàng!",
    CaresoftTicketError: "Lỗi tạo phiếu ghi CSKH sau bán hàng!",
  },
  RESPONSE_TYPES: {
    Success: "Success",
    Error: "Error",
  },
  STATUS: {
    Active: 1,
    InActive: 2,
  },
  STATUS_TEXT: {
    1: "Hoạt động",
    2: "Ngưng HĐ",
  },
  ROLES: {
    Agent: "agent",
    SuperAdmin: "super_admin",
    Owner: "owner",
    StockAdmin: "stock_admin",
  },
  NEXT_STEPS: {
    Showroom: "Khách hẹn lên showroom",
    Reject: "Khách từ chối không mua",
    Later: "Khách hẹn lần sau",
    Thinking: "Khách đang suy nghĩ",
    Online: "Khách đặt mua online",
    Bill: "Đã chốt bill",
  },
  ORDER_STATUS: {
    New: 1,
    Completed: 2,
    Cancel: 3,
    CustCancel: 4,
  },
  ORDER_STATUS_TEXT: {
    1: "Chờ giao",
    2: "Giao thành công",
    3: "Hủy đơn hàng",
    4: "Khách hủy đơn",
  },
  CHARGE_TYPES: {
    Cash: "1",
    Transfer: "2",
    Credit: "3",
    Installment: "4",
  },
  CHARGE_TYPES_TEXT: {
    1: "Tiền mặt",
    2: "Chuyển khoản",
    3: "Thẻ tín dụng",
    4: "Trả góp",
  },
  INSTALLMENT_MONTHS: {
    1: "Một tháng",
    2: "Hai tháng",
    3: "Ba tháng",
    4: "Bốn tháng",
    5: "Năm tháng",
    6: "Sáu tháng",
    7: "Bảy tháng",
    8: "Tám tháng",
    9: "Chín tháng",
    10: "Mười tháng",
    11: "Mười một tháng",
    12: "Mười hai tháng",
  },
  INSTALLMENT_STATUS: {
    InProgress: 1,
    Cancelled: 2,
    Completed: 3,
  },
  INSTALLMENT_STATUS_TEXT: {
    1: "Đang trả góp",
    2: "Hủy trả góp",
    3: "Trả góp xong",
  },
  MISC_TYPES: [
    "Comment",
    "Interest",
    "NextStep",
    "PriceRange",
    "Source",
    "SaleProgram",
    "KOL",
    "AdEvent",
  ],
  TITLES: {
    Mr: "Anh",
    Ms: "Chị",
    Md: "Cô",
    Sr: "Chú",
  },
  CALENDAR_TYPES: {
    Day: "Ngày",
    Month: "Tháng",
    Year: "Năm",
  },
  SHARED_TYPES_TEXT: {
    1: "TH1: Đã chốt, nhờ giao hộ ",
    2: "TH2: Tư vấn khách cũ giới thiệu mua thêm ",
    3: "TH3: Giới thiệu khách, 2 bên tư vấn",
  },
  PAYMENT_TYPES: {
    Deposit: 1,
    Prepay: 2,
    Income: 3, // 1. so tien con lai
    Outcome: 4, // 2. chuyen tra gop
    Monthly: 5,
  },
  PAYMENT_TYPES_TEXT: {
    1: "Cọc đơn hàng",
    2: "Trả trước",
    3: "Tiền còn lại",
    4: "Chuyển trả góp",
    5: "Trả góp",
  },
  DASHBOARD_TYPES_TEXT: {
    OrderTotalAgencies: "Doanh thu từng cửa hàng",
    CustomerVisitAgencies: "Lượt ghé thăm từng cửa hàng",
    CustomerVisit: "Kênh liên hệ của cửa hàng",
  },
  WARRANTY_TYPES_TEXT: {
    1: "Bảo hành sản phẩm",
    2: "Bảo hành về da",
    3: "Bảo hành về máy",
  },
  AGENCY_TYPES_TEXT: {
    1: "Cửa hàng K-Group",
    2: "Cửa hàng nhượng quyền",
  },
  AGENCY_TYPES: {
    KGroup: 1,
    Authorized: 2,
  },
  IMPORT_STATUS_TEXT: {
    1: "Chờ xác nhận",
    2: "Đang gửi tới",
    3: "Đã duyệt",
    4: "Hủy nhập kho",
    5: "Từ chối",
  },
  IMPORT_STATUS: {
    Waiting: 1, // Export => Waiting
    InComing: 2,
    Approved: 3, // Export => Approved
    Cancelled: 4, // Export => Cancelled
    Rejected: 5, // Export => Return
  },
  EXPORT_STATUS_TEXT: {
    1: "Chờ xác nhận",
    2: "Đang gửi đi",
    3: "Xác nhận",
    4: "Hủy xuất kho",
    5: "Trả lại",
  },
  EXPORT_STATUS: {
    Waiting: 1,
    OnGoing: 2, // Import => InComing
    Approved: 3,
    Cancelled: 4, // Import => Cancelled
    Returned: 5,
  },
  PRODUCT_TYPES: {
    NewBrand: 1,
    Liquidation: 2,
    GivingAway: 3,
    LikeNew: 4,
    Assets: 5,
  },
  PRODUCT_TYPES_TEXT: {
    1: "Hàng mới",
    2: "Hàng thanh lý",
    3: "Hàng tặng không bán",
    4: "Hàng mới 99%",
    5: "Vật tư cửa hàng",
  },
  EXPENSE_TYPES: {
    Fixed: 1,
    Flexible: 2,
  },
  EXPENSE_TYPES_TEXT: {
    1: "Định phí",
    2: "Biến phí",
  },
  DISCOUNT_TYPES: {
    Amount: 1,
    Percent: 2,
  },
  DISCOUNT_TYPES_TEXT: {
    1: "Giảm tiền",
    2: "Phần trăm",
  },
  BUYING_EXPERIENCES: {
    Counter: 1,
    Online: 2,
    Home: 3,
  },
  BUYING_EXPERIENCES_TEXT: {
    1: "Ghé Quầy",
    2: "Online",
    3: "Thử Ghế Tại Nhà",
  },
};
Contants.install = function(Vue) {
  Vue.prototype.$const = Contants;
};
export default Contants;
