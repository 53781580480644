import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store/store.js";

import moment from "moment";
moment.locale("vi");
Vue.prototype.$moment = moment;

import constPlugin from "./util/constant.js";
Vue.use(constPlugin);

import funcPlugin from "./util/functions.js";
Vue.use(funcPlugin);

import user from "./store/user.js";
Vue.prototype.$user = user;

import money from "v-money";
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: " VND",
  precision: 0,
  masked: false,
});

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

import Axios from "axios";
Vue.prototype.$http = Axios.create({
  //baseURL: "https://localhost:5001",
  baseURL: "https://api.ghemassageklc.com",
  timeout: 120000,
});

Vue.prototype.$http.interceptors.request.use(
  (config) => {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      let user = JSON.parse(userStr);
      config.headers["Authorization"] = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import { Settings as LuxonSettings } from "luxon";
LuxonSettings.defaultLocale = "vi-vn";

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
